import React, { useEffect, useState } from 'react';
import './styles.scss';
import BannerSlider from '../../components/home/BannerSlider';
import { useAccount } from '../../store/account';
import TitleBox from '../../ui-components/title-box';
import ItemAuction from './item-auction';
import ItemSmallAuction from './item-small-auction';
import ContactForm from '../../components/contact';
import { useAuction } from '../../store/auction';
import { Constants } from '../../Constants';
import moment from 'moment';
import ItemAuctionBig from './item-auction-big';
import thanks from '../../assets/images/thanks.jpg';
import { connect } from 'react-redux';
import Login from '../Login';

const Home = ({ sessionProps }) => {
  const accountStore = useAccount();
  const auctionStore = useAuction();
  const [intervalId, setIntervalId] = useState(null);

  const [auctionLists, setAuctionLists] = useState({
    inProgress: [],
    completed: [],
    pending: [],
  });

  useEffect(() => {
    //accountStore.getAccountConfig();
    if (!intervalId) {
      auctionStore.getAllAuctions();
      let id = setInterval(() => {
        auctionStore.getAllAuctions();
      }, 5000);
      setIntervalId(id);
    }
    document.title = 'Inicio';

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [intervalId]);

  useEffect(() => {
    if (accountStore.config?.title_tabs) {
      document.title = accountStore.config.title_tabs + ' - ' + document.title;
    }
  }, [accountStore.config]);

  useEffect(() => {
    if (null != auctionStore.auctions) {
      setAuctionLists({
        ...auctionLists,
        inProgress: auctionStore.auctions.data.inProgress,
        completed: auctionStore.auctions.data.completed,
        pending: auctionStore.auctions.data.pending,
      });
    }
  }, [auctionStore.auctions]);

  useEffect(() => {
  }, [auctionLists]);

  return (
    <div className='home-box'>
      <BannerSlider />

      {/*
        sessionProps.account !== null ?
          <div className='container' style={{ marginTop: '4rem' }}>
            <img src={thanks} alt="" style={{ width: '100%' }} />
          </div>
          : null*/
      }

      <section className='light' id="auctions-list">
        {0 < auctionLists.inProgress.length && (
          <div className='box container mt-5'>
            <div className='mb-5'>
              <TitleBox title='Subastas en proceso' />
            </div>
            <div className='row'>
              {auctionLists.inProgress.map((item) => (
                <div className='col-md-6 col-sm-12 mt-4'>
                  <ItemAuction
                    name={item.title}
                    auctionNumber={item.id}
                    dateFrom={`${moment(item.date_from).format(
                      'dddd D'
                    )} de ${moment(item.date_from).format('MMMM')}, ${moment(
                      item.date_from
                    ).format('HH:mm')}h.`}
                    dateTo={`${moment(item.date_to).format(
                      'dddd D'
                    )} de ${moment(item.date_to).format('MMMM')}, ${moment(
                      item.date_to
                    ).format('HH:mm')}h.`}
                    img={`${Constants.BASE_URL}${item.image_thumb}`}
                    lotsCount={item.count_lot}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        {0 < auctionLists.pending.length && (
          <div className='box container mt-5'>
            <div className='mb-5'>
              <TitleBox title='Próximas Subastas' />
            </div>

            <div className='row'>
              {auctionLists.pending.map((item) => (
                <div className='col-md-6 col-sm-12 mt-4'>
                  <ItemAuction
                    name={item.title}
                    auctionNumber={item.id}
                    dateFrom={`${moment(item.date_from).format(
                      'dddd D'
                    )} de ${moment(item.date_from).format('MMMM')}, ${moment(
                      item.date_from
                    ).format('HH:mm')}h.`}
                    dateTo={`${moment(item.date_to).format(
                      'dddd D'
                    )} de ${moment(item.date_to).format('MMMM')}, ${moment(
                      item.date_to
                    ).format('HH:mm')}h.`}
                    img={`${Constants.BASE_URL}${item.image_thumb}`}
                    lotsCount={item.count_lot}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </section>

      {0 < auctionLists.completed.length && (
        <section className='dark'>
          <div className='box container'>
            <div className='mb-5'>
              <TitleBox title='Subastas finalizadas' backgroundDark={true} />
            </div>

            <div className='row'>
              {auctionLists.completed.map((item) => (
                <div className='col-md-6 col-sm-12 position-relative mt-4'>
                  <ItemSmallAuction
                    name={item.title}
                    auctionNumber={item.id}
                    dateFinished={`${moment(item.date_to).format(
                      'dddd D'
                    )} de ${moment(item.date_to).format('MMMM')}, ${moment(
                      item.date_to
                    ).format('HH:mm')}h.`}
                    img={`${Constants.BASE_URL}${item.image_thumb}`}
                  />
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
      <section className='light'>
        {
          (window.socket_io_customer === 'remates_cardozo' || window.socket_io_customer === 'luisina_veiga') && sessionProps.account === null ? (
            <div className='box container'>
              <div className='row'>
                <div className='col-1'></div>
                <div className='col-md-10 col-sm-12'>
                  <Login />
                </div>
                <div className='col-1'></div>
              </div>
            </div>
          ) : null
        }
      </section >
    </div >
  );
};


const mapStateToProps = (state) => ({
  sessionProps: state.userReducer,
});

export default connect(mapStateToProps, null)(Home);
